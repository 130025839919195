<template>
  <v-app>
    <v-app-bar color="white" app>
      <Navbar />
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from '@/components/Footer'
import Navbar from '@/components/Navbar'
export default {
  components: { Navbar, Footer },
  name: 'App',
  data() {
    return {

    }
  }
}
</script>
