<template>
    <v-card-text>
        <v-form class="col-md-6 grid  justify-center ml-80 my-20">
            <v-text-field class="my-5" placeholder="Email"></v-text-field>
            <v-text-field class="my-5" placeholder="password"></v-text-field>
            <v-text-field class="my-5" placeholder="Forgot password"></v-text-field>
        <v-text-field class="my-5" placeholder="Create Account"></v-text-field>
        </v-form>
    </v-card-text>
</template>

<script>
export default {

}
</script>

<style scoped>
.form {
    padding: 10em;
    padding-left: 25em;
    padding-right: 25em;
}
</style>
