<template>
    <v-footer class="mt-1">

   
    <div class="col grid justify-center">
        <h5 class="mt-6 mb-4">Join our community</h5>
        
            <v-btn class="mb-2 normal-case" ><a class="text-black visited:text-purple-600" href="https://oxygen.co.tz/#/room/!qZAEEOTpitEHoPlVfw:app.oxygen.co.tz">Join us</a></v-btn>
            <v-btn class="normal-case">Add SmartMeter</v-btn>
        </div>
   
    </v-footer>
</template>

<script>
export default{
    
}
</script>

<style scoped>
/*footer {
    display: flex;
    padding-top:4em;
    margin-top:em;
}

.join {
    margin-left: 80em;
    margin-top:-15em;
}

.smart {
    margin-left:20em;
}

h4 {
    font-size: 1.5em;
    margin-left: 5em;
}

.follow {
    display: flex;
    margin-left: 2em;
}

img {
    width: 40%;
}*/

.facebook {
    margin-left: -13em;
    width: 100%;
}

.twitter {
    margin-left: 11em;
    width: 7%;
}

.insta {
    margin-left: 8em;
    width: 12%;
    margin-top:-5em;
}

p {
    margin-left: 8em;
    margin-top: 1em;
}

h5 {
    font-size: 1em;
}
.meter{
    margin-right:1em;
}
</style>