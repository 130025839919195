<template>
    <v-toolbar elevation="0">
       
       <!-- <v-btn @click="drawer = !drawer" >click me</v-btn>-->
       
        <v-img max-width="50" src="@/assets/tanza-removebg-preview.png"></v-img>
            <v-spacer></v-spacer>
            <v-btn  text v-for="link in links" :key="link.text" router :to="link.route">
                {{link.text}}
        </v-btn>
       
       
    </v-toolbar>

 <!----<v-navigation-drawer v-model="drawer" app class="indigo">
        <v-list>
            <v-list-tile>
                <v-list-tile-content>
                    <v-list-tile-title class="white--text">Dashboard</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
        </v-list>
    </v-navigation-drawer>-->

</template>

<script>
export default{
    data(){
        return{
            drawer:false,

            links:[
                {text:'Register', route: '/register'},
                {text: 'Login', route: '/Login'}
                
            ]
        }
    }

}
</script>