<template>
  <div class="container-fluid">

    <div class="row blue">
      <div class="col-md-6 flex flex-row justify-center">
        <v-img max-width="550" max-height="550" src="@/assets/smartmeter.png"></v-img>
      </div>
      <div class="col-md-6 flex flex-col justify-center">
        <h1 class="text-4xl text-white font-bold">Smart Energy Meter</h1>
        <p class="text-white my-12">Smart energy meter converts normal energy meters to smart energy meters, which will
          enable
          consuers and supply
          authorities/agencies to interact with meters.</p>
      </div>

    </div>


    <div class="row">
      <div class="col flex justify-center mt-16">
        <h2 class="text-3xl font-bold text-[#909090]">Why Smart Meter</h2>
      </div>
    </div>

    <div class="row">
      
      <div class="col-md-6">
        <div class="why">
          <p class="my-16 px-16 py-16">User interacts with his /her smart meter remotely by using his/her phone.
            With Smart meter there is no need of physical
            contact between the user and his/her meter.</p>
        </div>
      </div>
        <div class="col-md-6">
        
          <v-img class="mx-16" max-width="550" max-height="550" src="@/assets/original.jpeg"></v-img>
        </div>
    </div>


    <div class="row flex justify-center mb-2">
      <div class="col-md-3 mx-2 p-4 ">
        <div class="flex justify-center">
          <v-img max-width="60" src="@/assets/1-removebg-preview.png"></v-img>
        </div>
          
        <div class="add">
        <v-card class="p-4 " >
          <h4 class="font-medium mb-2 flex justify-center">Remotely Recharge</h4>
          <p class="texts">User can recharge the meter remotely by using his/her phone, there is no physical
            interaction
            of
            user and meter
          </p>
        </v-card>
        </div>
      </div>
      <div class="col-md-3 mx-2 p-4">
        <div class="flex justify-center">
          <v-img max-width="60" src="@/assets/m2.png"></v-img>
        </div>
         
        <div class="add">
        <v-card class="p-4 pt-2" >
          <h4 class="font-medium mb-2 flex justify-center">Units sharing</h4>
          <p class="texts">With smart energy meter you can share your units with your neighbour remotely by using mobile phones and no distance limitation
          </p>
        </v-card>
        </div>
      </div>

      <div class="col-md-3 mx-2 p-4">
        <div class="flex justify-center">
          <v-img max-width="60" src="@/assets/n3-removebg-preview.png"></v-img>
        </div>
        <div class="add">
        <v-card class="p-4 " >
          <h4 class="font-medium mb-2 flex justify-center">Energy consumption</h4>
          <p class="texts">Smart Energy shows Consumer’s energy consumption daily, weekly, monthly and annual usage
          </p>
        </v-card>
        </div>
      </div>
    </div>

    <div class="row flex justify-center mb-24">
      <div class="col-md-3 mx-2 p-4 ">
        <div class="flex justify-center">
          <v-img max-width="60" src="@/assets/n4-removebg-preview.png"></v-img>
        </div>
        <div class="add">
        <v-card class="p-4 ">
          <h4 class="font-medium mb-2 flex justify-center">Notifications</h4>
          <p class="texts">The low unit notification is sent to the customer, when the units are below threshold value, which is either defined by
          customer
          or the default value.
          </p>
        </v-card>
        </div>
      </div>
      <div class="col-md-3 mx-2 p-4">
        <div class="flex justify-center">
          <v-img max-width="60" src="@/assets/n5-removebg-preview.png"></v-img>
        </div>
     <div>
      <div class="add">
        <v-card class="p-4 ">
          <h4 class="font-medium mb-2 flex justify-center">See Unit Balance</h4>
          <p class="texts">Prepay householders can see how much credit they have left using their phones
          </p>
        </v-card>
        </div>
        </div>
      </div>
      
      <div class="col-md-3 mx-2 p-4">
        <div class="flex justify-center">
          <v-img max-width="60" src="@/assets/n6-removebg-preview.png"></v-img>
        </div>
        <div class="add">
        <v-card class="p-4 ">
          <h4 class="font-medium mb-2 flex justify-center">Energy consumption</h4>
          <p class="texts">Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident repellendus libero commodi nam sed cumque!
          </p>
        </v-card>
        </div>
      </div>
    </div>


    <div class="blue">
    <div class="row ">
      <div class="col-md-6 flex flex-row justify-center">
        <v-img max-width="550" max-height="550" src="@/assets/smartmeter.png"></v-img>
      </div>
      <div class="col-md-6 flex flex-col justify-center">
        <h1 class="text-4xl  font-700">About Smart  Meter</h1>
        <p class="text- my-12">Energy meters, allow users to interacts with the meter through the agencies’ central system, which involves, meter
        reading, recharging, and configurations, irrespective of the user’s location with respect to the meter’s location</p>
      </div>
    </div>


<div class="row flex justify-center mb-24 ">
  <div class="col-md-3 mx-2 p-4 ">

    <v-card class="p-4">
      <p class="texts">Smart meters communicate the information to the suppliers for system monitoring and customer billing.
      </p>
    </v-card>
  </div>

  <div class="col-md-3 mx-2 p-4 ">
  
    <v-card class="p-4">
      <p class="texts">Smart meters enable two-way communication between the meter and the central system (Energy supply agency/authority).
      </p>
    </v-card>
  </div>

  <div class="col-md-3 mx-2 p-4 ">
  
    <v-card class="p-4">
      <p class="texts">Smart meters typically record energy nearly in real-time, and report regularly, in short intervals of time throughout
      the day.
      </p>
    </v-card>
  </div>
  </div>
  </div>
    <!--<div class="rows">
      <v-row>
        <v-col col="12" sm="12" md="6" lg="3">
          <div class="text-1">
            
          </div>
        </v-col>
        <v-col col="12" sm="12" md="6" lg="3">
          <div class="text-1">
            <v-card class="text-center mx-5 py-10 px-10">
              <v-img class="image" max-width="60" src="@/assets/m2.png"></v-img>
              <h4>Units Sharing</h4>
              <p class="text">With smart energy meter you can share your units with your neighbour remotely lorem5</p>
            </v-card>
          </div>
        </v-col>

        <v-col col="12" sm="12" md="6" lg="3">
          <div class="text-1">
            <v-card :width="width" class="text-center mx-5 py-10 px-10">
              <v-img class="image" max-width="60" src="@/assets/n3-removebg-preview.png"></v-img>
              <h4>Energy Consumption</h4>
              <p class="text">Smart Energy shows Consumer’s energy consumption daily, weekly, monthly and annual usage
              </p>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <div class="row">
        <v-row>
          <v-col col="12" sm="12" md="6" lg="3">
            <div class="text-1">
              <v-card :width="width" class="text-center mx-5 py-10 px-10">
                <v-img class="image" max-width="60" src="@/assets/n4-removebg-preview.png"></v-img>
                <h4>Notifications</h4>
                <p class="text">The low unit notification is sent to the customer, when the units are below threshold
                  value, which
                  is either
                  defined by customer
                  or the default value. </p>
              </v-card>
            </div>
          </v-col>

          <v-col col="12" sm="12" md="6" lg="3">
            <div class="text-1">
              <v-card :width="width" class="text-center mx-5 py-10 px-10 ">
                <v-img class="image" max-width="60" src="@/assets/n5-removebg-preview.png"></v-img>
                <h4>See Unit Balance</h4>
                <p class="text">Prepay householders can see how much credit they have left using their phones Lorem
                  ipsum dolor sit
                  amet consectetur adipisicing elit. Dolorum, fuga!</p>
              </v-card>
            </div>
          </v-col>

          <v-col col="12" sm="12" md="6" lg="3">
            <div class="text-1">
              <v-card :width="width" class="text-center mx-5 py-10 px-10">
                <v-img class="image" max-width="60" src="@/assets/n6-removebg-preview.png"></v-img>
                <h4>Free Updates</h4>
                <p class="text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla maxime corporis
                  voluptate eaque
                  laudantium
                  porro odio eveniet quo suscipit officiis.</p>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>


    <div class=" abaut blue my-5">
      <div class="d-flex">
        <div class="mx-16 my-16 py-16">
          <h2 class="mx-16">About Smart Meter</h2>
          <div class="about">
            <p class="mx-16 my-10">Energy meters, allow users to interacts with the meter through the agencies’ central
              system, which
              involves, meter
              reading, recharging, and configurations, irrespective of the user’s location with respect to the meter’s
              location</p>
          </div>

        </div>
        <v-img class="mx-10" max-width="550" max-height="550" src="@/assets/smartmeter.png"></v-img>
      </div>

      <v-layout class="d-flex py-10 my-16 ml-16 pl-16">

        <v-card :width="width" class="text-center mx-5 grey">
          <p class="py-5 px-10">Smart meters enable two-way communication between the meter and the central system
            (Energy supply
            agency/authority).</p>
        </v-card>

        <v-card :width="width" class="text-center mx-5 grey">
          <p class="py-5 px-10">Smart meters typically record energy nearly in real-time, and report regularly, in short
            intervals of
            time throughout
            the day.</p>
        </v-card>

        <v-card :width="width" class="text-center mx-5 grey">
          <p class="py-5 px-10">Smart meters communicate the information to the suppliers for system monitoring and
            customer billing.
          </p>
        </v-card>


      </v-layout>
    </div> -->
  </div>


</template>

<script>
export default {
  name: 'Home',
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return
        case 'sm': return
        case 'md': return 300
        case 'lg': return 400
        case 'xl': return 500
      }
    }
  }
}
</script>

<style scoped>
.image {
  margin-left: 6em;
  margin-top: -5em;
}

.para {
  width: 50%;
  margin-left: 8em;
  padding-top: 2em;
  color: white;
  font-size: 1.3em;
}

.h1 {
  margin-left: 3.5em;
  font-size: 3em;
  color: white;
  padding-top: 2em;
}

.img {
  margin-right: 15em;
  margin-top: 4em;
  margin-bottom: 4em;
}

.container {
  width: 200em;
}

.h2 {
  text-align: center;
  font-size: 2em;
  font-weight: 700;
  padding-top: 3em;
  opacity: 0.5;
}

.text {
  width: 80%;
  text-align: left;
  padding: 0em;
  margin-left: 3.5em;
}

v-card {
  width: 40%;
}

.text-1:hover {
  background-color: rgba(128, 128, 128, 0.158);
  padding: 1em;
}

.cont {
  margin-left: -6em;
  margin-right: -6em;
  display: flex;
}

.abaut {
  margin-left: -5em;
  margin-right: -5em;
}

.lay {
  margin-left: 4em;
}

.row {
  margin-bottom: 2em;
}

.rows {
  margin-left: 14em;
}

.add:hover{
background-color:rgba(128, 128, 128, 0);
padding:1em;
}
</style>
